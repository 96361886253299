import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ContainerPosts, Footer, Link, Loading, Newsletter } from '~/components'
import * as st from '~/assets/styl/AuthorPage.module.styl'
import ArrowBackIcon from '~/assets/svg/icon-yellow-left.svg'
import linkedin from '~/assets/svg/linkedin.svg'
import mail from '~/assets/svg/mail.svg'
import { useApiBlog as api } from '~/siteApi'
import { context } from '~/layouts'

const AuthorPage = () => {
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [author, setAuthor] = useState<any>({})
  const getSlugFromUrl = () => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname
      const slug = path.split('/').filter(Boolean).pop()
      return slug
    }
    return null
  }
  const slug = getSlugFromUrl()

  const slugFormat = useMemo(() => {
    return slug
      ? slug
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '-')
          .toLowerCase()
      : ''
  }, [slug])

  const getCategoryNameById = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId)
    return category ? category.name : 'Categoria desconhecida'
  }

  const { currentPage } = useContext(context)
  const [totalPages, setTotalPages] = useState(0)
  const postsPerPage = 12

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const authorResponse = await api.requestData(
          'get',
          `users?slug=${slugFormat}`
        )
        if (authorResponse.data && authorResponse.data.length > 0) {
          const userResponse = await api.requestData(
            'get',
            `users/${authorResponse.data[0].id}`
          )
          setAuthor(userResponse.data)
        } else {
          console.error('Autor não encontrado.')
        }
      } catch (error) {
        console.error('Erro ao carregar o autor', error)
      }
    }

    fetchAuthor()
  }, [slugFormat])

  useEffect(() => {
    if (author.id) {
      const fetchPosts = async () => {
        try {
          const postsResponse = await api.requestData(
            'get',
            `posts?author=${author.id}&per_page=${postsPerPage}&page=${currentPage}`
          )
          setPosts(postsResponse.data)
          setTotalPages(postsResponse?.headers['x-wp-totalpages'])

          const getAllCategories = await api.requestData(
            'get',
            'categories?per_page=100'
          )
          setCategories(getAllCategories.data)
        } catch (error) {
          console.error('Erro ao carregar posts', error)
        }
      }

      fetchPosts()
    }
  }, [author.id, currentPage, postsPerPage])

  return (
    <>
      {(posts?.length > 0 && author?.name && (
        <div
          style={{ paddingLeft: '0', paddingRight: '0' }}
          className={st.core}
        >
          <div className={st.grid}>
            <div className={st.header}>
              <Link href="/blog">
                <img src={ArrowBackIcon} alt="Voltar" />
                Voltar
              </Link>
              <section className={st.author}>
                <img
                  src={author.avatar_urls?.['96']}
                  alt={author.name}
                  width={120}
                  height={120}
                  loading="lazy"
                />
                <h1 className={st.title}>{author.name}</h1>
                {author.job_title && (
                  <p className={st.job}>{author.job_title}</p>
                )}
                {(author.custom_meta.email ||
                  author.custom_meta.linkedin_profile) && (
                  <div className={st.links}>
                    {author.custom_meta.linkedin_profile && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={author.custom_meta.linkedin_profile || ''}
                      >
                        <img
                          src={linkedin}
                          alt="Linkedin"
                          width={25}
                          height={25}
                        />
                      </a>
                    )}
                    {author.custom_meta.email && (
                      <a href={`mailto:${author.custom_meta.email || ''}`}>
                        <img src={mail} alt="Envelope" width={29} height={23} />
                      </a>
                    )}
                  </div>
                )}
                <div className={st.description}>{author.description}</div>
              </section>
            </div>
            <div className={st.articles}>
              <p>Artigos de {author.name}</p>
            </div>
            <ContainerPosts
              categoriesName={getCategoryNameById}
              searchOrFilter
              posts={posts}
              totalPages={totalPages}
            />
          </div>
          <Newsletter categorieOrSearch />
        </div>
      )) || <Loading visible />}
      <Footer />
    </>
  )
}

export default AuthorPage
